import { useEffect, useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SaveIcon from '@mui/icons-material/Save';
import { RootState } from 'redux/reducers';
import { TriageRegistrationConfig } from '@eql-ai/typescript-types';
import { LoadingButton } from '@mui/lab';
import parsePhoneNumber from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import PortalDatePicker from 'components/PortalDatePicker';
import containSpecialCharOrDigits from 'validation/containSpecialCharOrDigits';
import validateEmailAddress from 'validation/emailValidation';
import PhoneField from 'components/PhoneField';
import { PATIENTS } from 'core/utils/collectionNames';
import { firestoreInstance } from 'config/ApiService';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { ExtendedPatient, registerPatient } from 'services/patient';
import { LOG_TYPES } from 'utils/Logger';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import dayjs, { Dayjs } from 'dayjs';

interface PatientFormProps {
    onDismiss: () => void;
    patient?: ExtendedPatient;
    registrationConfig: TriageRegistrationConfig | null | undefined;
}

const PatientForm = (props: PatientFormProps) => {
    const { onDismiss, patient, registrationConfig } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [firstName, setFirstName] = useState(patient?.first_name || '');
    const [lastName, setLastName] = useState(patient?.last_name || '');
    const [email, setEmail] = useState(patient?.email || '');
    const [dob, setDob] = useState(patient?.dob === 'missing' ? '' : patient?.dob || '');
    const [phoneNumber, setPhoneNumber] = useState(
        patient?.phone ? parsePhoneNumber(patient?.phone) : null
    );

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [dobError, setDobError] = useState('');

    // This prevents the initial render showing the error messages
    const [isInitialRender, setIsInitialRender] = useState(true);

    const user = useSelector((state: RootState) => state.AuthReducer.user);
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const validateFirstName = () => {
        let isValid = true;

        if (!firstName || firstName === '') {
            isValid = false;
            setFirstNameError('First name is required.');
        }
        if (firstName && firstName.trim().length < 2) {
            isValid = false;
            setFirstNameError('First name needs to be min 2 characters.');
        }
        if (firstName && firstName.trim().length >= 2 && containSpecialCharOrDigits(firstName)) {
            isValid = false;
            setFirstNameError('Please do not use special characters or digits');
        }
        if (firstName && firstName.trim().length >= 2 && !containSpecialCharOrDigits(firstName)) {
            isValid = true;
            setFirstNameError('');
        }

        return isValid;
    };

    const validateLastName = () => {
        let isValid = true;

        if (!lastName) {
            isValid = false;
            setLastNameError('Last name is required.');
        }
        if (lastName && lastName.trim().length < 2) {
            isValid = false;
            setLastNameError('Last name needs to be min 2 characters.');
        }
        if (lastName && lastName.trim().length >= 2 && containSpecialCharOrDigits(lastName)) {
            isValid = false;
            setLastNameError('Please do not use special characters or digits');
        }
        if (lastName && lastName.trim().length >= 2 && !containSpecialCharOrDigits(lastName)) {
            isValid = true;
            setLastNameError('');
        }

        return isValid;
    };

    const validateEmail = () => {
        let isValid = true;
        const value = email?.trim();

        if (!value && !phoneNumber) {
            isValid = false;
            setEmailError('Either Email address or Phone number is required.');
        }
        if (value && !validateEmailAddress(value)) {
            isValid = false;
            setEmailError('Please enter a valid email.');
        }
        if (value && validateEmailAddress(value)) {
            isValid = true;
            setEmailError('');
        }

        return isValid;
    };

    const validatePhone = () => {
        let isValid = true;

        if (!phoneNumber && !email) {
            isValid = false;
            setPhoneError('Either Email address or Phone number is required.');
        }
        if (phoneNumber && !phoneNumber.isValid()) {
            isValid = false;
            setPhoneError('Please enter a valid phone number');
        }
        if (phoneNumber && phoneNumber.isValid()) {
            isValid = true;
            setPhoneError('');
        }

        return isValid;
    };

        const formattedDob = dayjs(dob).format('YYYY-MM-DD');
        const isValidDob = dayjs(formattedDob).isValid();

    const validateDob = () => {
        let isValid = true;
        const minPatientAge = registrationConfig?.min_patient_age || 16;
        const minDate = dayjs()
            .startOf('day')
            .subtract(minPatientAge, 'year')
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);
        const formattedDob = dayjs(dob).format('DD/MM/YYYY');

        if (!dob) {
            isValid = false;
            setDobError('Date of Birth is required.');
        }
        if (dob && isValidDob === false) {
            isValid = false;
            setDobError('Please enter a dob');
        }
        if (dob && dayjs(minDate).diff(formattedDob) < 0) {
            isValid = false;
            setDobError(`Must be over ${registrationConfig?.min_patient_age || 16} years old`);
        }
        if (dob && dayjs(minDate).diff(formattedDob) > 0 && isValidDob === true) {
            isValid = true;
            setDobError('');
        }

        return isValid;
    };

    const defaultPhone = (patient?.phone && parsePhoneNumber(patient.phone)) || null;

    const handleDateOfBirthChange = (date: Dayjs | null) => {
        if (!dayjs(date).isValid()) {
            setDob('');
            return;
        }
        setDob(dayjs(date, 'DD/MM/YYYY').format('DD/MM/YYYY'));
    };

    const updatePatient = async () => {
        if (!patient?.id) {
            return;
        }

        const ref = doc(firestoreInstance(), PATIENTS, patient.id);
        await updateDoc(ref, {
            first_name: firstName,
            last_name: lastName,
            email,
            dob: dob && dob.toString().includes('-') ? dob : dayjs(dob,'DD/MM/YYYY').format('YYYY-MM-DD'),
            phone: phoneNumber?.number || '',
            last_updated_at: serverTimestamp(),
            last_updated_by: user.email
        });
    };

    const addPatient = async () => {
        const res = await registerPatient({
            client_id: registrationConfig?.client_id,
            client_name: registrationConfig?.client_name,
            first_name: firstName,
            last_name: lastName,
            email,
            dob: dayjs(dob).format('YYYY-MM-DD'),
            phone: phoneNumber?.number || '',
            referral_type: 'admin',
            case_status: 'open',
            ip_reference: registrationConfig?.ip_reference,
            ip_name: registrationConfig?.provider_route
        });

        await dispatch(
            AuthActionCreators.logForAudit({
                target: {
                    id: user.id,
                    description: 'Phio Access patient created',
                    affectedUser: res?.data?.patient_id
                },
                type: LOG_TYPES.CREATE
            })
        );
    };

    const handleUpdatePatient = async () => {
        try {
            await updatePatient();

            enqueueSnackbar(`Successfully updated`, {
                variant: 'success'
            });
        } catch (error) {
            console.log(error);

            enqueueSnackbar(`There was an issue updating patient, please try again.`, {
                variant: 'error'
            });
        } finally {
            setIsSubmitting(false);
            onDismiss();
        }
    };

    const handleAddPatient = async () => {
        try {
            await addPatient();

            enqueueSnackbar(`Patient successfully created`, {
                variant: 'success'
            });
        } catch (error) {
            console.log(error);

            enqueueSnackbar(`There was an issue creating patient, please try again.`, {
                variant: 'error'
            });
        } finally {
            setIsSubmitting(false);
            onDismiss();
        }
    };

    const handleSubmit = async () => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        const isFormValid = [
            validateFirstName(),
            validateLastName(),
            validateEmail(),
            validatePhone(),
            validateDob()
        ].every((isValid) => isValid);

        if (!isFormValid) {
            setIsSubmitting(false);
            return;
        }

        if (patient?.id) {
            await handleUpdatePatient();
            return;
        }

        await handleAddPatient();
    };
    useEffect(() => {
        if (isInitialRender) {
            return;
        }
        validateDob();
        // eslint-disable-next-line
    }, [dob]);

    useEffect(() => {
        setIsInitialRender(false);
    }, []);

    return (
        <Box px={2} pb={4} pt={6}>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, lg: 6 }}>
                    <TextField
                        label="First Name"
                        value={firstName}
                        onChange={(evt) => setFirstName(evt.target.value)}
                        fullWidth
                        onBlur={validateFirstName}
                        error={!!firstNameError}
                        helperText={firstNameError}
                        required
                    />
                </Grid>
                <Grid size={{ xs: 12, lg: 6 }}>
                    <TextField
                        label="Last Name"
                        value={lastName}
                        onChange={(evt) => setLastName(evt.target.value)}
                        fullWidth
                        onBlur={validateLastName}
                        error={!!lastNameError}
                        helperText={lastNameError}
                        required
                    />
                </Grid>
                <Grid size={{ xs: 12, lg: 6 }}>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(evt) => setEmail(evt.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                        onBlur={validateEmail}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid size={{ xs: 12, lg: 6 }}>
                    <PortalDatePicker
                        label="Date of Birth"
                        inputFormat="DD/MM/YYYY"
                        date={dob}
                        onDateInputChange={(date) => handleDateOfBirthChange(date)}
                        error={Boolean(dobError) ? { message: dobError } : undefined}
                        required
                    />
                </Grid>
                <Grid size={{ xs: 12, lg: 6 }}>
                    <PhoneField
                        label="Phone"
                        defaultPhoneNumber={defaultPhone?.number || ''}
                        defaultCountryIso2={defaultPhone?.country?.toLowerCase() || 'gb'}
                        onChange={(number) => {
                            setPhoneNumber(number);
                        }}
                        onBlur={validatePhone}
                        error={!!phoneError}
                        helperText={phoneError}
                        required
                    />
                </Grid>
            </Grid>

            <Box my={3} display="flex" justifyContent="flex-end">
                <Box mr={2}>
                    <Button variant="contained" onClick={onDismiss} color="inherit">
                        Cancel
                    </Button>
                </Box>
                <LoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    loading={isSubmitting}
                    loadingPosition="end"
                    endIcon={<SaveIcon />}
                    disabled={isSubmitting}
                >
                    Save
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default PatientForm;
